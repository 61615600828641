/** Form elements */
.ant-input {
  &.ant-input-lg {
    font-size: 1.4rem !important;

    &:not(textarea) {
      height: 4rem;
    }
  }

  &:focus {
    box-shadow: none;
  }
}

.ant-input-number {
  width: 100%;

  &.ant-input-number-focused {
    box-shadow: none;
  }
}

.ant-select-focused {
  .ant-select-selector {
    box-shadow: none !important;
  }
}

.ant-select-selector {
  height: 4rem !important;
}

.ant-select-selection-placeholder, .ant-select-selection-item {
  font-size: 1.4rem;
  font-weight: 500;
}

.ant-select-selection-search-input {
  height: 3.6rem !important;
}

.ant-select-selection-placeholder {
  line-height: 3.6rem !important;
}

.ant-select-selection-item {
  line-height: 3.8rem !important;
  font-weight: 500 !important;
}

.ant-select-item-option-selected {
  background-color: #d5d5d5 !important;
}

.ant-select-tree-node-selected {
  color: #ffffff !important;
}

.ant-select-arrow {
  .anticon {
    svg {
      font-size: 1.6rem;
    }
  }
}

.ant-input-password {
  &.ant-input-password-large {
    .ant-input-password-icon {
      svg {
        font-size: 1.6rem !important;
      }
    }
  }

  &.ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
}

.ant-input-affix-wrapper {
  box-shadow: none !important;
  padding-top: 0;
  padding-bottom: 0;

  .ant-input {
    height: 3.8rem;
  }
}

.ant-form-item-explain-error {
  font-size: 1.3rem;
}

.ant-picker {
  &.ant-picker-focused {
    box-shadow: none;
  }
}

.ant-select-item-option-content {
  font-size: 1.4rem;
}

.ant-select-selection-search-input {
  font-size: 1.4rem !important;
}

.ant-checkbox {
  + span {
    font-size: 1.4rem;
  }
}

.ant-switch {
  &.dark {
    &:not(.ant-switch-checked) {
      background: var(--color-primary-light) !important;
    }

    &.ant-switch-checked {
      background: var(--color-green) !important;
    }
  }
}

.ant-select-clear {
  margin-top: -8px !important;
  right: 14px !important;
}

/** Date picker */
.ant-picker-suffix, .ant-picker-clear {
  svg {
    font-size: 1.4rem;
  }
}

.ant-picker-cell-inner {
  font-size: 1.4rem;
}

.ant-picker-content {
  th {
    font-size: 1.4rem;
  }
}

.ant-picker-month-btn, .ant-picker-year-btn, .ant-picker-today-btn, .ant-picker-decade-btn, .ant-picker-header-view {
  font-size: 1.4rem !important;
}

.ant-picker-today-btn {
  color: var(--color-brand) !important;
}

.ant-picker {
  width: 100%;
}

.ant-picker-large {
  height: 40px;
}

.ant-picker-input {
  input {
    font-size: 1.4rem !important;
  }
}

/** Button */
.ant-btn {
  box-shadow: none;

  &.ant-btn-lg {
    span {
      font-size: 1.4rem;
    }
  }

  &.ant-btn-sm {
    span {
      font-size: 1.3rem;
    }
  }
}

.ant-btn-primary {
  background-color: var(--color-primary) !important;
}

.ant-btn-default {
  &:hover {
    color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
  }
}

.ant-btn[disabled] {
  color: var(--color-primary) !important;
  background-color: #d9d9d9 !important;

  &:hover {
    background-color: #d9d9d9 !important;
    border-color: #d9d9d9 !important;
  }
}

/** Menu */
.ant-dropdown-menu-title-content {
  font-size: 1.4rem;
}

.ant-menu-item-group-title {
  font-weight: bold;
  transition: none !important;
}

.ant-dropdown-menu-item-icon {
  font-size: 1.6rem !important;
}

.ant-dropdown-menu-item-only-child {
  padding: 0 !important;
}

.ant-dropdown-menu-title-content > div {
  padding: 5px 12px !important;
}

.ant-menu-title-content {
  font-size: 1.4rem;
  font-weight: 500;
  transition: none !important;
}

.ant-menu-item-selected {
  background-color: var(--color-primary) !important;
  color: white !important;
}

.ant-menu-inline-collapsed {
  .ant-menu-item-group-title {
    position: relative;
    font-size: 0;

    &:before {
      display: block;
      content: '';
      width: 100%;
      height: 1px;
      background-color: #E4E6E8;
    }
  }

  .ant-menu-item-group:first-child {
    .ant-menu-item-group-title {
      display: none;
    }
  }
}

/** Tabs */
.ant-tabs-tab-btn {
  @apply px-4 py-3.5;
  font-size: 1.4rem;
}

.ant-tabs-tab {
  padding: 0 !important;

  + .ant-tabs-tab {
    margin-left: 0 !important;
  }
}

.ant-tabs-content-holder {
  border-left-color: var(--color-border) !important;
}

.ant-tabs-dropdown-menu-item {
  span {
    font-size: 1.4rem;
  }
}

/** Tooltip */
.ant-tooltip-inner {
  font-size: 1.2rem;
}

/** Empty */
.ant-empty-description {
  font-size: 1.3rem;
}

/** Icon */
.anticon {
  svg {
    font-size: 1.4rem;
  }
}

.ant-alert {
  .anticon {
    svg {
      font-size: 2.2rem;
    }
  }
}

.ant-notification {
  .anticon {
    svg {
      font-size: 2.4rem;
    }
  }
}


/** Drawer */
.ant-drawer-header-title {
  display: flex;
  flex-direction: row-reverse;
}

.ant-drawer-close {
  margin-right: 0 !important;
}

/** Divider */
.ant-divider {
  border-inline-start: 1px solid rgb(226, 232, 240);
}

/** Collapse */
.ant-collapse-item {
  .ant-collapse-header {
    align-items: center !important;
  }
}

/** Table */
.ant-table-cell {
  font-size: 1.4rem;
}

th.ant-table-cell {
  font-weight: bold !important;
}

.ant-table-wrapper {
  .ant-pagination-item {
    a {
      font-size: 1.4rem;
    }
  }

  .ant-pagination {
    margin-left: 20px !important;
    margin-right: 20px !important;
    align-items: center;
  }
}

tr.ant-table-row-selected {
  td {
    background-color: lightgray !important;
  }
}

/** Message */
.ant-message-custom-content {
  span {
    font-size: 1.4rem;
  }
}

/** Typography */
.ant-typography {
  span, .ant-typography-expand {
    font-size: 1.4rem;
  }
}

/** Modal */
.fullWidthModalContentModalWrapper {
  .ant-modal-content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  .ant-modal-title {
    padding-left: 24px;
  }
}

/** Tree */
.ant-select-tree-list-holder-inner {
  .ant-select-tree-title {
    font-size: 1.4rem;
  }

  .ant-select-tree-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}