.ql-toolbar.ql-snow {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
}

.ql-editor {
  padding: 24px !important;
  min-height: 200px;

  p, strong, em, u, s, li:before {
    font-size: 1.4rem;
  }

  h1 {
    font-size: 2em;

    * {
      font-size: inherit;
    }
  }
}

.ql-active {
  .ql-stroke {
    stroke: var(--color-brand) !important;
  }

  .ql-fill {
    fill: var(--color-brand) !important;
  }
}

.ql-formats {
  margin-bottom: 5px;

  button, .ql-picker-label, .ql-picker-item, .ql-picker-label {
    font-size: 1.4rem;

    &:hover {
      color: var(--color-brand) !important;

      .ql-stroke {
        stroke: var(--color-brand) !important;
      }

      .ql-fill {
        fill: var(--color-brand) !important;
      }
    }
  }

  .ql-html {
    position: relative;

    &:before {
      display: block;
      content: '</>';
      font-weight: bold;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
    }
  }
}

.ql-align-center {
  text-align: center;
}


.ql-article {
  h1 {
    @apply text-lg;
    * {
      @apply text-lg;
    }
  }

  p {
    @apply text-sm mb-0;
    & * {
      @apply text-sm mb-0;
    }
  }
}