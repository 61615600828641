#nprogress {
  .bar {
    height: 4px !important;
    background: var(--color-primary) !important;
  }

  .peg {
    box-shadow: 0 0 10px var(--color-primary), 0 0 5px var(--color-primary) !important;
  }

  .spinner-icon {
    border-top-color: var(--color-primary) !important;
    border-left-color: var(--color-primary) !important;
  }
}