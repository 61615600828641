:root {
  --color-primary: #171721;
  --color-primary-light: #8083a3;
  --color-text-light: #636470;
  --color-brand: #be3491;
  --color-link: #3e7eff;
  --color-background: #ecf2ff;
  --color-background-light: #f5f8fc;
  --color-green: #3fb768;
  --color-border: #e4e6e8;
  --color-warning: #ffcd00;

  --gradient-01: linear-gradient(77.36deg, rgba(156, 206, 252, 0) 0.39%, #aefdf2 99.77%),
  linear-gradient(0deg, #dce4fe, #dce4fe);
  --gradient-02: linear-gradient(248.96deg, #aefcf2 3.61%, #fcd6ff 100%);
  --gradient-03: linear-gradient(to right, rgba(223, 204, 255, 1) 0%, rgba(255, 171, 226, 1) 100%);
}