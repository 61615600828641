.title {
  position: relative;
  margin: 0;
  z-index: 3;
  padding: 0 10px;
  animation: jump 1s;
  animation-fill-mode: forwards;

  &:nth-of-type(1) {
    animation: jump 1s ease-in-out alternate 0.05s infinite;
  }

  &:nth-of-type(2) {
    animation: jump 1s ease-in-out alternate 0.1s infinite;
  }

  &:nth-of-type(3) {
    animation: jump 1s ease-in-out alternate 0.15s infinite;
  }

  &:nth-of-type(4) {
    animation: jump 1s ease-in-out alternate 0.2s infinite;
  }

  &:nth-of-type(5) {
    animation: jump 1s ease-in-out alternate 0.25s infinite;
  }
}

@keyframes jump {
  0% {
    transform: translate3d(0, 0, 0);
  }

  90% {
    transform: translate3d(0, -100%, 0);
  }

  100% {
    transform: translate3d(0, -200%, 0);
  }
}
